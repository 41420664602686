<template>
	<b-modal ref="sync-groups-modal" id="sync-groups-modal" title="Sync Groups">
		<p class="my-4">Warning: Syncing groups could corrupt the save, make sure to save a backup first by clicking 'save' below</p>
		<p class="my-2">Syncing groups can fix problems where references don't appear to be in the right group (e.g. a reference in the 'exclude' group is displayed in undecided).</p>
		<template #modal-footer>
			<b-button @click="hide()">
				Cancel
			</b-button>
			<b-button variant="info" @click="save">
				Save
			</b-button>
			<b-button variant="danger" @click="syncGroups">
				Sync Groups
			</b-button>
		</template>
	</b-modal>
</template>

<script>
import saveMixin from "../mixins/saveMixin"

export default {
	name: 'TheSyncGroupsModal',
	mixins: [
		saveMixin
	],
	data() {
		return {
		}
	},
	mounted() {
		this.$root.$on('show-sync-groups-modal', () => {
			this.$refs['sync-groups-modal'].show();
		});
	},
	methods: {
		hide() {
			this.$refs['sync-groups-modal'].hide();
		},
		syncGroups() {
			// 1. Uses customGroups getter to get a group list
			let groupList = this.$store.getters['groups/customGroups'];
			let allRefs = this.$store.getters['references/getAllRefs'];

			// 2. For each group 'name' key:
			groupList.forEach(group => {
				console.log(group.name)
				// a. Filter the getAllRefs object with only values where the 'group' key is equal to that name
				let refsObject = {};

				for (let key in allRefs) {
					if (Object.prototype.hasOwnProperty.call(allRefs, key) && allRefs[key].group === group.key) {
						refsObject[key] = allRefs[key];
					}
				}

				// b. Use pushRefsToGroup to push those references to the group after removing from all other groups
				this.$store.commit('groups/removeRefsFromGroups', refsObject);
				this.$store.commit('groups/pushRefsToGroup', { groupKey: group.key, refs: refsObject });
			});

			this.$refs['sync-groups-modal'].hide();
    }
	}
}
</script>

<style>
</style>