import filenameMixin from './filenameMixin';

export default {
	mixins: [filenameMixin],
	methods: {
		save() {
			const saveObject = {
				metadata: JSON.parse(JSON.stringify(this.$store.state.metadata.metadata)),
				refs: JSON.parse(JSON.stringify(this.$store.state.references.refs)),
				groupList: this.$store.getters['groups/getGroupListCopy'],
				hotkeys: [...this.$store.state.hotkeys.hotkeys],
				includeKeywords: [...this.$store.state.keywords.include],
				excludeKeywords: [...this.$store.state.keywords.exclude],
			}

			return this.$tera.setProjectFileContents(new Blob([JSON.stringify(saveObject, null, '\t')], {type: 'application/json'}), {
				title: 'Save screening file',
				hint: 'screening',
			});

		}
	}
}
